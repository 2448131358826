export const NorthIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8843 6.72577C14.9603 7.13895 15 7.56484 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 7.56484 1.03971 7.13895 1.1157 6.72577C2.50963 5.9919 5.07099 5.5 8 5.5C10.929 5.5 13.4904 5.9919 14.8843 6.72577ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 4.27116e-08 12.4183 9.5399e-08 8C1.48086e-07 3.58172 3.58172 4.27116e-08 8 9.5399e-08C12.4183 1.48086e-07 16 3.58172 16 8Z"
        fill="white"
      />
    </svg>
  )
}
