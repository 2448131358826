export const WestIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.72577 1.1157C7.13896 1.03971 7.56484 0.999999 8 0.999999C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C7.56484 15 7.13895 14.9603 6.72577 14.8843C5.9919 13.4904 5.5 10.929 5.5 8C5.5 5.07099 5.9919 2.50963 6.72577 1.1157ZM8 -6.99382e-07C12.4183 -3.13124e-07 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 3.13124e-07 12.4183 6.99382e-07 8C1.08564e-06 3.58172 3.58172 -1.08564e-06 8 -6.99382e-07Z"
        fill="white"
      />
    </svg>
  )
}
