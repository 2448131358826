export const SouthEastIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.03308 13.7702C3.68718 13.5317 3.35796 13.2587 3.05025 12.951C0.316583 10.2173 0.316582 5.78513 3.05025 3.05146C5.78392 0.317791 10.2161 0.317791 12.9497 3.05146C13.2575 3.35917 13.5305 3.68839 13.769 4.03429C13.3022 5.53887 11.8389 7.69785 9.76777 9.76898C7.69665 11.8401 5.53767 13.3034 4.03308 13.7702ZM2.34315 13.6581C-0.781048 10.5339 -0.781048 5.46855 2.34315 2.34435C5.46734 -0.77984 10.5327 -0.77984 13.6569 2.34435C16.781 5.46855 16.781 10.5339 13.6569 13.6581C10.5327 16.7823 5.46734 16.7823 2.34315 13.6581Z"
        fill="white"
      />
    </svg>
  )
}
