import React from 'react'
import { Col, Row } from 'react-grid-system'
import Link from 'next/link'
import styles from './Breadcrumbs.module.scss'
import { BreadcrumbProps } from '~types'
import { ArrowBackIcon } from '../Icons'

const Breadcrumbs = ({ items = [] }: BreadcrumbProps) => {
  return (
    <div className={styles['breadcrumbs']}>
      <Row>
        <Col>
          <ol className={styles['breadcrumb-list']}>
            {items.map((item, index) => (
              <li key={index} className={styles['breadcrumb-item']}>
                {item.href ? (
                  <Link href={item.href || '#'} className={styles['breadcrumb-link']}>
                    <ArrowBackIcon />
                    {item.label}
                  </Link>
                ) : (
                  <span>{item.label}</span>
                )}
              </li>
            ))}
          </ol>
        </Col>
      </Row>
    </div>
  )
}

export default Breadcrumbs
