export const NorthEastIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7692 11.9669C13.5308 12.3128 13.2577 12.642 12.95 12.9497C10.2163 15.6834 5.78416 15.6834 3.05049 12.9497C0.316815 10.2161 0.316814 5.78392 3.05048 3.05025C3.35819 2.74255 3.68741 2.46948 4.03331 2.23104C5.5379 2.69778 7.69688 4.16111 9.768 6.23223C11.8391 8.30335 13.3024 10.4623 13.7692 11.9669ZM13.6571 13.6569C10.5329 16.781 5.46757 16.781 2.34338 13.6569C-0.780816 10.5327 -0.780817 5.46734 2.34338 2.34314C5.46757 -0.78105 10.5329 -0.781051 13.6571 2.34314C16.7813 5.46734 16.7813 10.5327 13.6571 13.6569Z"
        fill="white"
      />
    </svg>
  )
}
