export const SouthWestIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.23081 4.03308C2.46925 3.68718 2.74232 3.35796 3.05002 3.05025C5.78369 0.316582 10.2158 0.316582 12.9495 3.05025C15.6832 5.78392 15.6832 10.2161 12.9495 12.9497C12.6418 13.2575 12.3126 13.5305 11.9667 13.769C10.4621 13.3022 8.30312 11.8389 6.232 9.76777C4.16088 7.69665 2.69755 5.53766 2.23081 4.03308ZM2.34291 2.34314C5.46711 -0.78105 10.5324 -0.781049 13.6566 2.34315C16.7808 5.46734 16.7808 10.5327 13.6566 13.6569C10.5324 16.781 5.46711 16.781 2.34291 13.6569C-0.78128 10.5327 -0.78128 5.46734 2.34291 2.34314Z"
        fill="white"
      />
    </svg>
  )
}
