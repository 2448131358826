export const NorthWestIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9669 2.22984C12.3128 2.46827 12.642 2.74134 12.9497 3.04904C15.6834 5.78271 15.6834 10.2149 12.9497 12.9485C10.2161 15.6822 5.78392 15.6822 3.05025 12.9485C2.74255 12.6408 2.46948 12.3116 2.23105 11.9657C2.69778 10.4611 4.16111 8.30215 6.23223 6.23102C8.30335 4.1599 10.4623 2.69658 11.9669 2.22984ZM13.6569 2.34194C16.781 5.46613 16.781 10.5315 13.6569 13.6556C10.5327 16.7798 5.46734 16.7798 2.34315 13.6556C-0.781048 10.5315 -0.781048 5.46613 2.34315 2.34194C5.46734 -0.782257 10.5327 -0.782257 13.6569 2.34194Z"
        fill="white"
      />
    </svg>
  )
}
