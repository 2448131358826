export const TopIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9497 3.04904C10.2161 0.315374 5.78392 0.315374 3.05025 3.04904C0.316583 5.78271 0.316582 10.2149 3.05025 12.9485C5.78392 15.6822 10.2161 15.6822 12.9497 12.9485C15.6834 10.2149 15.6834 5.78271 12.9497 3.04904ZM13.6569 2.34194C10.5327 -0.782257 5.46734 -0.782257 2.34315 2.34194C-0.781048 5.46613 -0.781048 10.5315 2.34315 13.6556C5.46734 16.7798 10.5327 16.7798 13.6569 13.6556C16.781 10.5315 16.781 5.46613 13.6569 2.34194Z"
        fill="white"
      />
      <circle cx="8" cy="7.9934" r="3.03468" transform="rotate(-135 8 7.9934)" fill="white" stroke="white" />
    </svg>
  )
}
