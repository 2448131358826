import { IVPCategory, IVPThumbnailDetails } from 'interfaces/VantagePoints'
import { LOCAL_STORAGE } from '~constants'

interface IVantagePointData {
  data: {
    categorizedData: Array<IVPCategory>
    rawData: Array<IVPThumbnailDetails>
  }
}
export const fetchVantagePoints = async (): Promise<IVantagePointData> => {
  try {
    const headers = new Headers()
    const userSessionData = localStorage.getItem(LOCAL_STORAGE.USER_KEY)

    if (!userSessionData) {
      throw new Error('Session data is missing.')
    }

    const { id } = JSON.parse(userSessionData)

    headers.append('Authorization', `Bearer ${id}`)
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/vantage-points`, {
      method: 'GET',
      headers
    })
    const results: { data: Array<IVPThumbnailDetails> } = { data: await result.json() }

    let categorizedData: Array<IVPCategory> = []
    let currCategoryId = ''
    let currCategoryIdx = -1
    let currGroupId = ''
    let currGroupIdx = -1

    for (let i = 0; i < results.data.length; i++) {
      let curr: IVPThumbnailDetails = results.data[i]
      const thumbnailUrl = `https://${process.env.EXO_CLOUDFRONT_DIST}/${curr.thumbnail_image_key}`
      const currWithThumbnailUrl = { ...curr, thumbnail_url: thumbnailUrl }

      if (currCategoryId !== curr.category_id) {
        categorizedData.push({
          id: curr.category_id,
          category_name: curr.category_name,
          groups: [
            {
              id: curr.group_id,
              group_name: curr.group_name,
              vantage_points: [{ ...currWithThumbnailUrl }]
            }
          ]
        })
        currCategoryId = curr.category_id
        currGroupId = curr.group_id
        currCategoryIdx++
        currGroupIdx = 0
      } else if (currCategoryId === curr.category_id) {
        if (currGroupId !== curr.group_id) {
          const newGroup = {
            id: curr.group_id,
            group_name: curr.group_name,
            vantage_points: [{ ...currWithThumbnailUrl }]
          }
          categorizedData[currCategoryIdx].groups.push(newGroup)
          currGroupId = curr.group_id
          currGroupIdx++
        } else {
          categorizedData[currCategoryIdx].groups[currGroupIdx].vantage_points.push({ ...currWithThumbnailUrl })
        }
      }
    }
    return { data: { categorizedData, rawData: results.data } }
  } catch (e) {
    throw new Error('Unable to get vantage point data.')
  }
}
