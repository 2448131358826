export const SouthIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.1157 9.27423C1.03971 8.86105 1 8.43516 1 8C1 4.13401 4.13401 0.999999 8 1C11.866 1 15 4.13401 15 8C15 8.43516 14.9603 8.86105 14.8843 9.27423C13.4904 10.0081 10.929 10.5 8 10.5C5.07099 10.5 2.50963 10.0081 1.1157 9.27423ZM-3.49691e-07 8C-1.56562e-07 3.58172 3.58172 -5.4282e-07 8 -3.49691e-07C12.4183 -1.56562e-07 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 -5.4282e-07 12.4183 -3.49691e-07 8Z"
        fill="white"
      />
    </svg>
  )
}
