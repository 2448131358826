import RenderStreamingManager from 'render-streaming-client/build/RenderStreamingManager'
import DownloadVideoCommand from 'render-streaming-client/build/command/DownloadVideoCommand'
import SetTimeOfDayCommand from 'render-streaming-client/build/command/SetTimeOfDayCommand'
import ApplyVantagePointCommand from 'render-streaming-client/build/command/ApplyVantagePointCommand'
import ResetPreviewRuntimeCommand from 'render-streaming-client/build/command/ResetPreviewRuntimeCommand'
import CustomCommand from 'render-streaming-client/build/command/CustomCommand'
import styles from './index.module.scss'
import { useEffect, useState } from 'react'
import type { TRenderStreamingConfiguration } from 'render-streaming-client/build/ConfigurationTypes'
import { PlayPauseButton } from './PlayPauseButton'
import { usePreviewStore } from 'store'
import { useRouter } from 'next/router'
import { DEFAULT_TIME_OF_DAY_UUID, DEFAULT_VANTAGE_POINT_UUID, PREVIEW_DISCONNECT_TYPE, PREVIEW_LOGIN_PATH } from '~constants'
import { handlePreviewDisconnect } from '~utils'
import { getVideos } from 'services/getVideos'
import { createPreviewUrl } from 'services/createPreviewUrl'
import { fetchVantagePoints } from 'services/getVantagePoints'
import { fetchTimesOfDay } from 'services/getTimesOfDay'

const configuration: TRenderStreamingConfiguration = {
  matchmaker: {
    port: parseInt(`${process.env.NEXT_PUBLIC_MATCHMAKER_PORT}`) || 3000,
    domain: `${process.env.NEXT_PUBLIC_MATCHMAKER_URL}`,
    enabled: true,
    secure: true
  },
  signaling: {
    secure: true
  }
}

const RenderClient = () => {
  const [streaming, setStreaming] = useState<boolean>(false)
  const [manager, setManager] = useState<RenderStreamingManager | null>(null)
  const [playing, setPlaying] = useState<boolean>(false)
  const {
    setRenderingManager,
    renderingManager,
    selectedVideo,
    setSelectedVideo,
    resetRenderingManager,
    allVPCategories,
    setAllVPCategories,
    setAllVantagePoints,
    allTimesOfDay,
    setAllTimesOfDay
  } = usePreviewStore()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isReady, setIsReady] = useState<boolean>(false)
  const router = useRouter()

  useEffect(() => {
    const sixtyMinutes = 1000 * 60 * 60
    const timer = setTimeout(() => {
      router.push(PREVIEW_LOGIN_PATH).then(() => {
        handlePreviewDisconnect({
          renderingManager,
          resetRenderingManager,
          type: PREVIEW_DISCONNECT_TYPE.IDLE_TIMEOUT
        })
      })
    }, sixtyMinutes)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    // Before creating a renderStreamingManager we need to make sure a selectedVideo exists.
    if (!selectedVideo.url && router.query.id && !isReady) {
      getVideos().then(async videos => {
        const selectedVideo = videos.data.filter(video => {
          const { id } = router.query

          if (!id) {
            throw Error('Missing query parameter id')
          }

          return video.id == id
        })

        if (selectedVideo.length === 0) {
          throw Error('No video could be found.')
        }

        const presignedUrl = await createPreviewUrl(selectedVideo[0].title)

        setSelectedVideo({ url: presignedUrl, fileName: selectedVideo[0].title })
        setIsReady(true)
      })
    } else if (!isReady && selectedVideo.url) {
      setIsReady(true)
    }
  }, [selectedVideo, router])

  useEffect(() => {
    if (isReady) {
      const fetchVPAndToDData = async () => {
        if (!allVPCategories?.length) {
          await getAllVantagePoints()
        }
        if (!allTimesOfDay?.length) {
          await getAllTimesOfDay()
        }
      }
      fetchVPAndToDData().catch(error => console.error(error))

      const renderStreamingManager = new RenderStreamingManager(configuration)

      ;(renderStreamingManager._pixelStreaming.videoElementParent.firstElementChild?.nextElementSibling as HTMLElement)!.style.top = '0'

      setManager(renderStreamingManager)

      return () => {
        renderStreamingManager
          ?.disconnect()
          .then(() => console.log('[UNMOUNTED]: RenderClient has unmounted and disconnected current connection.'))
          .catch(err => console.error(`[UNMOUNTED]: There was this ${err} error when disconnecting.`))
        renderStreamingManager._pixelStreaming.videoElementParent.remove()
      }
    }
  }, [isReady])

  // useEffect(() => {
  //   const handleRouteChange = (url, options) => {
  //     debugger
  //     setDisplayExitModal(true)
  //     router.events.emit('routeChangeError')
  //     throw 'Waiting for the preview modal to display.'
  //   }

  //   router.events.on('routeChangeStart', handleRouteChange)

  //   return () => {
  //     router.events.off('routeChangeStart', handleRouteChange)
  //     setDisplayExitModal(false)
  //   }
  // }, [])

  useEffect(() => {
    if (manager && isReady) {
      manager.onCommandResponse('get-preview-engine-build-version-command', response => {
        const parseResponse = JSON.parse(response)
        console.log('SPE Version: ', parseResponse.Payload.BuildVersionInfo)
      })

      manager.onStatusChanged((oldStatus, newStatus) => {
        console.log('RenderStreaming status changed', oldStatus, newStatus)
      })

      /**
       * Listen for Render Streaming events!
       */
      //
      // Signaling Events
      //
      manager.onSignalingConnection(e => {
        console.log('Connected to Signaling Server')
      })
      manager.onSignalingDisconnect(e => {
        console.log('Disconnected from Signaling Server')
      })
      manager.onWebRtcConnection(e => {
        console.log('Connected to Unity APP')
      })
      //
      // WebRTC Events
      //
      manager.onWebRtcDisconnect(e => {
        console.log('Disconnected from Unity APP')
      })
      manager.onWebRtcDatachannelOpen(e => {
        console.log('Data channel to Unity APP opened')
        //The data channel needs a little bit of time before it is ready to receive a command
      })

      manager.onWebRtcDatachannelClosed(() => {
        console.log('Data channel to Unity APP closed!')
      })
      //
      // Matchmaker Events
      //
      manager.onMatchmakerConnection(() => {
        console.log('Connected to Matchmaker')
      })
      // Event dispatched at signaling match
      manager.onMatchmakerSignalingDataReceived(signalingUrl => {
        console.log(`Matched signaling server connection at ${signalingUrl}`)
      })
      manager.onMatchmakerDisconnect(reason => {
        console.log('Disconnected from Matchmaker')
      })

      console.log('hooking ready')
      manager.onRenderStreamingReady(() => {
        setTimeout(function () {
          downloadAndPlayVideo()
          setStreaming(true)
        }, 1000)
      })

      manager._pixelStreaming.addEventListener('webRtcDisconnected', resetPixelStreaming)

      console.log('FiringBeginStream')
      beginStreaming()
      manager._pixelStreaming.emitUIInteraction({})

      return () => {
        manager?._pixelStreaming.removeEventListener('webRtcDisconnected', resetPixelStreaming)
      }
    }
  }, [manager, isReady])

  const resetPixelStreaming = e => {
    manager?._pixelStreaming.removeEventListener('webRtcDisconnected', resetPixelStreaming)

    if (e.data.eventString === 'too many connections. max: 2, current: 2') {
      manager
        ?.disconnect()
        .then(() => {
          manager._pixelStreaming.videoElementParent.remove()

          console.warn(`Resetting rendering streaming manager due to ${e.data.eventString}`)

          const renderStreamingManager = new RenderStreamingManager(configuration)

          ;(renderStreamingManager._pixelStreaming.videoElementParent.firstElementChild?.nextElementSibling as HTMLElement)!.style.top = '0'

          setManager(renderStreamingManager)
        })
        .catch(error => {
          console.error(`Unable to disconnect this connection ${error}`)
          window.location.replace('/preview')
        })
    }
  }

  const beginStreaming = () => {
    if (!manager) {
      throw new Error('Unable to start streaming because streaming manager is not defined.')
    }

    manager.startStreaming()
  }

  const applyDefaultSettings = () => {
    if (!manager) {
      throw new Error('Unable to change settings because streaming manager is not defined.')
    }

    const applyDefaultTimeOfDay = new SetTimeOfDayCommand(DEFAULT_TIME_OF_DAY_UUID)
    const applyDefaultVantagePoint = new ApplyVantagePointCommand(DEFAULT_VANTAGE_POINT_UUID)
    const getPreviewEngineBuildVersionCommand = new CustomCommand('get-preview-engine-build-version-command', {})

    manager.executeCommand(applyDefaultTimeOfDay)
    manager.executeCommand(applyDefaultVantagePoint)

    manager.onCommandResponse(applyDefaultVantagePoint, data => {
      console.log('Default vantage point', JSON.parse(data))
    })

    // This command is not recognized and will throw errors.
    // manager.executeCommand(getPreviewEngineBuildVersionCommand)
  }

  const downloadAndPlayVideo = () => {
    console.log('Trying to start a download!!')
    if (!manager) {
      throw new Error('Unable to play video because streaming manager is not defined.')
    }

    const downloadVideo = new DownloadVideoCommand(selectedVideo.url)

    manager.onCommandResponse(downloadVideo, data => {
      const response = JSON.parse(data)

      // These two strings are returned by the render-streaming module when a file has finished downloading.
      if (response.Payload.downloadStatus && response.Payload.downloadStatus.match(/(ALREADY DOWNLOADED|DOWNLOAD COMPLETED)/) !== null) {
        const ResetVideoCommand = new ResetPreviewRuntimeCommand()
        manager.onCommandResponse(ResetVideoCommand, data => {
          // Use zustand global store to set the manager to be available for other components.
          setRenderingManager(manager)
          applyDefaultSettings()
        })
        manager.executeCommand(ResetVideoCommand)

        setPlaying(true)
        setTimeout(() => setIsLoading(false), 1500)
      }
    })
    manager.executeCommand(downloadVideo)
  }

  const getAllVantagePoints = async () => {
    try {
      const {
        data: { categorizedData, rawData }
      } = await fetchVantagePoints()
      setAllVPCategories(categorizedData)
      setAllVantagePoints(rawData)
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }

      throw new Error('Unknown error in fetching vantage points.')
    }
  }

  const getAllTimesOfDay = async () => {
    try {
      const { data: timesOfDayData } = await fetchTimesOfDay()
      setAllTimesOfDay(timesOfDayData)
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(err.message)
      }

      throw new Error('Unknown error in fetching times of day.')
    }
  }

  // FOR DEBUGGING:
  // manager.onAnyCommand(data => {
  //   const jsonObject = JSON.parse(data)
  //   console.log('jsonObject:', jsonObject)
  // })

  return (
    <div className={styles['render-client-container']}>
      <div id="container" className={styles['video-container']}>
        <div id="player" className={styles['video-player']} />
        {streaming ? <PlayPauseButton playing={playing} streaming={streaming} setPlaying={setPlaying} /> : null}
      </div>
      {isLoading ? (
        <div className={styles['loading__overlay']}>
          <p>LOADING...</p>
        </div>
      ) : null}
    </div>
  )
}

export default RenderClient
