export const EastIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.27423 14.8843C8.86105 14.9603 8.43516 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C8.43516 1 8.86105 1.03971 9.27423 1.1157C10.0081 2.50963 10.5 5.07099 10.5 8C10.5 10.929 10.0081 13.4904 9.27423 14.8843ZM8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16Z"
        fill="white"
      />
    </svg>
  )
}
