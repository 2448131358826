import { IVPThumbnailDetails } from 'interfaces/VantagePoints'

export const getSelected = (selectedVantagePointId: string, data: IVPThumbnailDetails[]) => {
  for (let i = 0; i < data.length; i++) {
    const vantagePoint = data[i]
    if (vantagePoint.unreal_guid === selectedVantagePointId) {
      return vantagePoint
    }
  }
}
